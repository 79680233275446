<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Productsunderdevelopment" style="width:100vw">
  <el-tabs id="ad8405f8" type="card">
    <el-tab-pane label="待确认事项">
      <div id="a64607a4">
        <div id="ad823c7c">
          <div id="a7f7e58c">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="a07a740e">
            待确认
          </div>
          <el-select id="c0e02be4" v-model="val_c0e02be4">
            <el-option v-for="item in op_c0e02be4" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-ad924508.png" id="ad924508"/>
        <div id="ab9cb674">
          <div id="e372d395">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="ad9b8044">
            待确认
          </div>
          <el-select id="ab07aaa6" v-model="val_ab07aaa6">
            <el-option v-for="item in op_ab07aaa6" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-f493c9e7.png" id="f493c9e7"/>
        <div id="aaf44c76">
          <div id="ae2c56e7">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="adcbcbc9">
            待确认
          </div>
          <el-select id="c21f0224" v-model="val_c21f0224">
            <el-option v-for="item in op_c21f0224" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-d58af581.png" id="d58af581"/>
        <div id="bd65bee9">
          <div id="aafed85f">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="a1e1fe07">
            待确认
          </div>
          <el-select id="ac772f15" v-model="val_ac772f15">
            <el-option v-for="item in op_ac772f15" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-ede93e20.png" id="ede93e20"/>
        <div id="a52d07c5">
          <div id="af460834">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="ad4e6003">
            待确认
          </div>
          <el-select id="fbd23368" v-model="val_fbd23368">
            <el-option v-for="item in op_fbd23368" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-a3b5a3a7.png" id="a3b5a3a7"/>
        <div id="a1e41e1d">
          <div id="a3dc4481">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="abf2b494">
            待确认
          </div>
          <el-select id="e25b70e7" v-model="val_e25b70e7">
            <el-option v-for="item in op_e25b70e7" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="已确认事项">
      <div id="aab1546b">
        <div id="a06b157d">
          <div id="cbcd6a3e">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="cde3bef4">
            已确认
          </div>
          <el-select id="a0cf7ab8" v-model="val_a0cf7ab8">
            <el-option v-for="item in op_a0cf7ab8" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-a68eae4c.png" id="a68eae4c"/>
        <div id="ae4596b4">
          <div id="add9c3e9">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="ea7d0fc8">
            已确认
          </div>
          <el-select id="a2dc31df" v-model="val_a2dc31df">
            <el-option v-for="item in op_a2dc31df" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-a1b1d8ec.png" id="a1b1d8ec"/>
        <div id="af270198">
          <div id="fd36c78c">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="a44e63e2">
            已确认
          </div>
          <el-select id="a167fd12" v-model="val_a167fd12">
            <el-option v-for="item in op_a167fd12" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-a847a7a9.png" id="a847a7a9"/>
        <div id="a7ecae4c">
          <div id="da8d6098">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="acd6e574">
            已确认
          </div>
          <el-select id="e8bb3f18" v-model="val_e8bb3f18">
            <el-option v-for="item in op_e8bb3f18" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-a858fc61.png" id="a858fc61"/>
        <div id="a9e74e68">
          <div id="bb6f4a57">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="a8a1d8ce">
            已确认
          </div>
          <el-select id="f421ae5c" v-model="val_f421ae5c">
            <el-option v-for="item in op_f421ae5c" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="../assets/img-a00182d7.png" id="a00182d7"/>
        <div id="f96d0f27">
          <div id="a6638e6b">
            待确认事项的内容待确认事项的内容待确认事项的内容
          </div>
          <div id="aa71e676">
            已确认
          </div>
          <el-select id="a6648333" v-model="val_a6648333">
            <el-option v-for="item in op_a6648333" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
  <div id="f01a2828">
    需求说明书
  </div>
  <div id="a5be5568">
    <div id="a09fba47">
      <div id="ecc4a522">
        <div id="dccc8b0c">
          产品设计
        </div>
        <div id="a77ebbc3">
          文档名称文档名称文档名
        </div>
        <div id="aa224b24">
          文档名称文档名称文档名称文
        </div>
        <div id="f3d4b8a3">
          文档名称文档名称文档名称文档名称文
        </div>
      </div>
      <div id="a8ef0d0b">
        <div id="a20725f4">
          软件开发
        </div>
        <div id="a82eb1c4">
          预计XX工作日完成
        </div>
        <div id="b1b8f802">
          预计XX工作日完成
        </div>
        <div id="a67ecb1c">
          预计XX工作日完成
        </div>
      </div>
      <div id="dc401b93">
        <div id="a6ad5c6e">
          部署上线
        </div>
        <div id="a8c0f494">
          www.fangwendizhi.com
        </div>
        <div id="aa8642e5">
          www.fangwendizhi.com
        </div>
        <div id="ce23ff53">
          www.fangwendizhi.com
        </div>
      </div>
    </div>
    <div id="a254178c">
      <img src="../assets/img-afafb143.png" id="afafb143"/>
      <img src="../assets/img-add36637.png" id="add36637"/>
      <img src="../assets/img-f7592bde.png" id="f7592bde"/>
      <img src="../assets/img-a06bf1a7.png" id="a06bf1a7"/>
      <img src="../assets/img-dbfb1e24.png" id="dbfb1e24"/>
      <img src="../assets/img-c676fcba.png" id="c676fcba"/>
      <img src="../assets/img-ac18bb5a.png" id="ac18bb5a"/>
      <img src="../assets/img-a8e81c65.png" id="a8e81c65"/>
      <img src="../assets/img-ac77d7d3.png" id="ac77d7d3"/>
      <img src="../assets/img-a3688d58.png" id="a3688d58"/>
      <img src="../assets/img-a012048f.png" id="a012048f"/>
    </div>
    <div id="bd68a1ec">
      <div id="a95c04b7">
        <div id="f16a9818">
          系统设计
        </div>
        <div id="ad09a0cb">
          文档名称文档名称文档名
        </div>
        <div id="ab68c09a">
          文档名称文档名称文档名称文
        </div>
        <div id="ad2bbd62">
          文档名称文档名称文档名称文档名称文
        </div>
      </div>
      <div id="a60ec5e7">
        <div id="a2aca4c4">
          软件测试
        </div>
        <div id="d1596788">
          v1.01测试报告(1)
        </div>
        <div id="a5fb6222">
          v1.01测试报告(1)
        </div>
        <div id="e2c5d4b1">
          v1.01测试报告(1)
        </div>
      </div>
      <div id="a803171f">
        软著证书
      </div>
    </div>
  </div>
  <div id="efd86405">
    <dal-compfloat id="cmpefd86405">
    </dal-compfloat>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_compfloat from '../components/dal-compfloat'
export default{
  components:{
    'dal-compfloat':dal_compfloat
  },
  data(){
    return{
    op_c0e02be4:[],
    val_c0e02be4:'',
    op_ab07aaa6:[],
    val_ab07aaa6:'',
    op_c21f0224:[],
    val_c21f0224:'',
    op_ac772f15:[],
    val_ac772f15:'',
    op_fbd23368:[],
    val_fbd23368:'',
    op_e25b70e7:[],
    val_e25b70e7:'',
    op_a0cf7ab8:[],
    val_a0cf7ab8:'',
    op_a2dc31df:[],
    val_a2dc31df:'',
    op_a167fd12:[],
    val_a167fd12:'',
    op_e8bb3f18:[],
    val_e8bb3f18:'',
    op_f421ae5c:[],
    val_f421ae5c:'',
    op_a6648333:[],
    val_a6648333:''
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#ad8405f8{
  width:1323px;
  padding-left:163px;
  padding-top:100px;
}
#ad8405f8{
  vertical-align: top;
}
#ad8405f8 /deep/ .el-tabs__item{
  font-size:30px;
  font-family:PingFang SC;
}
#a64607a4{
  width:1323px;
}
#a64607a4{
  background-color:rgb(252,252,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:4px 4px 15px rgba(0,0,0,0.20);
}
#ad823c7c{
  width:1173px;
  position:relative;
  padding-left:75px;
  padding-top:50px;
}
#a7f7e58c{
  display:inline-block;
  vertical-align: middle;
}
#a7f7e58c{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a07a740e{
  display:inline-block;
  vertical-align: middle;
}
#a07a740e{
  text-align:center;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#ad823c7c .el-select{
  width:37px;
  margin-left:68px;
  padding-top:8px;
  padding-bottom:11px;
}
#ad924508{
  width:1216px;
  height:2px;
  margin-top:40px;
  padding-left:54px;
}
#ad924508{
  vertical-align:middle;
}
#ab9cb674{
  width:1173px;
  position:relative;
  margin-top:40px;
  padding-left:75px;
}
#e372d395{
  display:inline-block;
  vertical-align: middle;
}
#e372d395{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#ad9b8044{
  display:inline-block;
  vertical-align: middle;
}
#ad9b8044{
  text-align:left;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#ab9cb674 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#f493c9e7{
  width:1216px;
  height:2px;
  margin-top:40px;
  padding-left:54px;
}
#f493c9e7{
  vertical-align:middle;
}
#aaf44c76{
  width:1173px;
  position:relative;
  margin-top:40px;
  padding-left:75px;
}
#ae2c56e7{
  display:inline-block;
  vertical-align: middle;
}
#ae2c56e7{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#adcbcbc9{
  display:inline-block;
  vertical-align: middle;
}
#adcbcbc9{
  text-align:left;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#aaf44c76 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#d58af581{
  width:1216px;
  height:2px;
  margin-top:40px;
  padding-left:54px;
}
#d58af581{
  vertical-align:middle;
}
#bd65bee9{
  width:1173px;
  position:relative;
  margin-top:40px;
  padding-left:75px;
}
#aafed85f{
  display:inline-block;
  vertical-align: middle;
}
#aafed85f{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a1e1fe07{
  display:inline-block;
  vertical-align: middle;
}
#a1e1fe07{
  text-align:left;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#bd65bee9 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#ede93e20{
  width:1216px;
  height:2px;
  margin-top:40px;
  padding-left:54px;
}
#ede93e20{
  vertical-align:middle;
}
#a52d07c5{
  width:1173px;
  position:relative;
  margin-top:40px;
  padding-left:75px;
}
#af460834{
  display:inline-block;
  vertical-align: middle;
}
#af460834{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#ad4e6003{
  display:inline-block;
  vertical-align: middle;
}
#ad4e6003{
  text-align:left;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#a52d07c5 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#a3b5a3a7{
  width:1216px;
  height:2px;
  margin-top:40px;
  padding-left:54px;
}
#a3b5a3a7{
  vertical-align:middle;
}
#a1e41e1d{
  width:1173px;
  position:relative;
  margin-top:40px;
  padding-left:75px;
  padding-bottom:50px;
}
#a3dc4481{
  display:inline-block;
  vertical-align: middle;
}
#a3dc4481{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#abf2b494{
  display:inline-block;
  vertical-align: middle;
}
#abf2b494{
  text-align:left;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#a1e41e1d .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#aab1546b{
  width:1323px;
}
#aab1546b{
  background-color:rgb(252,252,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:4px 4px 15px rgba(0,0,0,0.20);
}
#a06b157d{
  width:1173px;
  position:relative;
  padding-left:75px;
  padding-top:50px;
}
#cbcd6a3e{
  display:inline-block;
  vertical-align: middle;
}
#cbcd6a3e{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#cde3bef4{
  display:inline-block;
  vertical-align: middle;
}
#cde3bef4{
  text-align:center;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#a06b157d .el-select{
  width:37px;
  margin-left:68px;
  padding-top:8px;
  padding-bottom:11px;
}
#a68eae4c{
  width:1216px;
  height:2px;
  margin-top:40px;
  padding-left:54px;
}
#a68eae4c{
  vertical-align:middle;
}
#ae4596b4{
  width:1173px;
  position:relative;
  margin-top:40px;
  padding-left:75px;
}
#add9c3e9{
  display:inline-block;
  vertical-align: middle;
}
#add9c3e9{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#ea7d0fc8{
  display:inline-block;
  vertical-align: middle;
}
#ea7d0fc8{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#ae4596b4 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#a1b1d8ec{
  width:1216px;
  height:2px;
  margin-top:40px;
  padding-left:54px;
}
#a1b1d8ec{
  vertical-align:middle;
}
#af270198{
  width:1173px;
  position:relative;
  margin-top:40px;
  padding-left:75px;
}
#fd36c78c{
  display:inline-block;
  vertical-align: middle;
}
#fd36c78c{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a44e63e2{
  display:inline-block;
  vertical-align: middle;
}
#a44e63e2{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#af270198 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#a847a7a9{
  width:1216px;
  height:2px;
  margin-top:40px;
  padding-left:54px;
}
#a847a7a9{
  vertical-align:middle;
}
#a7ecae4c{
  width:1173px;
  position:relative;
  margin-top:40px;
  padding-left:75px;
}
#da8d6098{
  display:inline-block;
  vertical-align: middle;
}
#da8d6098{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#acd6e574{
  display:inline-block;
  vertical-align: middle;
}
#acd6e574{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#a7ecae4c .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#a858fc61{
  width:1216px;
  height:2px;
  margin-top:40px;
  padding-left:54px;
}
#a858fc61{
  vertical-align:middle;
}
#a9e74e68{
  width:1173px;
  position:relative;
  margin-top:40px;
  padding-left:75px;
}
#bb6f4a57{
  display:inline-block;
  vertical-align: middle;
}
#bb6f4a57{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a8a1d8ce{
  display:inline-block;
  vertical-align: middle;
}
#a8a1d8ce{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#a9e74e68 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#a00182d7{
  width:1216px;
  height:2px;
  margin-top:40px;
  padding-left:54px;
}
#a00182d7{
  vertical-align:middle;
}
#f96d0f27{
  width:1173px;
  position:relative;
  margin-top:40px;
  padding-left:75px;
  padding-bottom:50px;
}
#a6638e6b{
  display:inline-block;
  vertical-align: middle;
}
#a6638e6b{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#aa71e676{
  display:inline-block;
  vertical-align: middle;
}
#aa71e676{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#f96d0f27 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#f01a2828{
  margin-top:100px;
}
#f01a2828{
  text-align:center;
  color:#1c1c1c;
  font-size:45px;
  font-family:PingFang SC;
}
#a5be5568{
  width:1140px;
  height:1306px;
  position:relative;
  margin-top:94px;
  margin-left:252px;
  margin-bottom:409px;
}
#a5be5568{
  background-image:url('../assets/img-f795ab8a.png');
  background-size: cover;
}
#a09fba47{
  width:515px;
  position:relative;
  padding-bottom:34px;
  display:inline-block;
  vertical-align: middle;
}
#ecc4a522{
  width:515px;
  position:relative;
}
#dccc8b0c{
}
#dccc8b0c{
  text-align:right;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#a77ebbc3{
  margin-top:30px;
}
#a77ebbc3{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#aa224b24{
  margin-top:30px;
}
#aa224b24{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#f3d4b8a3{
  margin-top:30px;
}
#f3d4b8a3{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a8ef0d0b{
  width:251px;
  position:relative;
  margin-top:228px;
  padding-left:264px;
}
#a20725f4{
}
#a20725f4{
  text-align:right;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#a82eb1c4{
  margin-top:30px;
}
#a82eb1c4{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#b1b8f802{
  margin-top:30px;
}
#b1b8f802{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a67ecb1c{
  margin-top:30px;
}
#a67ecb1c{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#dc401b93{
  width:332px;
  position:relative;
  margin-top:228px;
  padding-left:183px;
}
#a6ad5c6e{
}
#a6ad5c6e{
  text-align:right;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#a8c0f494{
  margin-top:30px;
}
#a8c0f494{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#aa8642e5{
  margin-top:30px;
}
#aa8642e5{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#ce23ff53{
  margin-top:30px;
}
#ce23ff53{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a254178c{
  width:50px;
  position:relative;
  margin-left:30px;
  padding-top:3px;
  padding-bottom:3px;
  display:inline-block;
  vertical-align: middle;
}
#afafb143{
  width:50px;
  height:50px;
}
#afafb143{
  vertical-align:middle;
}
#add36637{
  width:12px;
  height:190px;
  margin-top:5px;
  padding-left:19px;
}
#add36637{
  vertical-align:middle;
}
#f7592bde{
  width:50px;
  height:50px;
  margin-top:5px;
}
#f7592bde{
  vertical-align:middle;
}
#a06bf1a7{
  width:12px;
  height:190px;
  margin-top:5px;
  padding-left:19px;
}
#a06bf1a7{
  vertical-align:middle;
}
#dbfb1e24{
  width:50px;
  height:50px;
  margin-top:5px;
}
#dbfb1e24{
  vertical-align:middle;
}
#c676fcba{
  width:12px;
  height:190px;
  margin-top:5px;
  padding-left:19px;
}
#c676fcba{
  vertical-align:middle;
}
#ac18bb5a{
  width:50px;
  height:50px;
  margin-top:5px;
}
#ac18bb5a{
  vertical-align:middle;
}
#a8e81c65{
  width:12px;
  height:190px;
  margin-top:5px;
  padding-left:19px;
}
#a8e81c65{
  vertical-align:middle;
}
#ac77d7d3{
  width:50px;
  height:50px;
  margin-top:5px;
}
#ac77d7d3{
  vertical-align:middle;
}
#a3688d58{
  width:12px;
  height:190px;
  margin-top:5px;
  padding-left:19px;
}
#a3688d58{
  vertical-align:middle;
}
#a012048f{
  width:50px;
  height:50px;
  margin-top:5px;
}
#a012048f{
  vertical-align:middle;
}
#bd68a1ec{
  width:515px;
  position:relative;
  margin-left:30px;
  padding-top:250px;
  display:inline-block;
  vertical-align: middle;
}
#a95c04b7{
  width:515px;
  position:relative;
}
#f16a9818{
}
#f16a9818{
  text-align:left;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#ad09a0cb{
  margin-top:30px;
}
#ad09a0cb{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#ab68c09a{
  margin-top:30px;
}
#ab68c09a{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#ad2bbd62{
  margin-top:30px;
}
#ad2bbd62{
  text-align:right;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a60ec5e7{
  width:220px;
  position:relative;
  margin-top:228px;
}
#a2aca4c4{
}
#a2aca4c4{
  text-align:right;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#d1596788{
  margin-top:30px;
}
#d1596788{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a5fb6222{
  margin-top:30px;
}
#a5fb6222{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#e2c5d4b1{
  margin-top:30px;
}
#e2c5d4b1{
  text-align:left;
  color:#6f7484;
  font-size:30px;
  font-family:PingFang SC;
}
#a803171f{
  margin-top:228px;
}
#a803171f{
  text-align:right;
  color:#1c1c1c;
  font-size:40px;
  font-family:PingFang SC;
}
#efd86405{
  width:150px;
  position:fixed;
  z-index: 50;
  top:1832px;
  left:1740px;
}
#efd86405{
  background-color:rgb(20,22,32,1);
  border-radius:5px 5px 5px 5px;
}
/*在此注释下方添加自定义样式*/
</style>
